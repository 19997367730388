import { getFirestore, collection, getDocs, getDoc, query, where, doc, updateDoc, arrayUnion, setDoc, deleteDoc } from 'firebase/firestore';
import { auth } from './firebaseConfig';


export const getDataWithQuery = async (collectionPath, whereConditions) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        const db = getFirestore();
        const collectionRef = collection(db, collectionPath);

        // Create an array to hold all the where conditions
        const whereQueries = whereConditions.map(condition =>
            where(condition.field, condition.operator, condition.value)
        );

        // Construct the query with all where conditions
        const q = query(collectionRef, ...whereQueries);

        const querySnapshot = await getDocs(q);

        const data = [];
        querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
        });

        return data;
    } catch (error) {
        console.error("Error fetching data from Firebase:", error);
        throw error;
    }
};

export const getAllData = async (path) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        const db = getFirestore();
        const tasksCollectionRef = collection(db, path);
        const querySnapshot = await getDocs(tasksCollectionRef);

        const tasks = [];
        querySnapshot.forEach((doc) => {
            tasks.push({ id: doc.id, ...doc.data() });
        });

        return tasks;
    } catch (error) {
        console.error("Error fetching tasks from Firebase:", error);
        throw error;
    }
};

export const fetchTaskLifestyleConfig = async (collections, document) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        const db = getFirestore();
        const configDocRef = doc(db, collections, document);
        const docSnapshot = await getDoc(configDocRef);

        if (docSnapshot.exists()) {
            return { id: docSnapshot.id, ...docSnapshot.data() };
        } else {
            console.error("Document not found");
            throw new Error("Document not found");
        }
    } catch (error) {
        console.error("Error fetching task lifestyle config from Firebase:", error);
        throw error;
    }
};

export const getTaskById = async (taskId, path) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        const db = getFirestore();
        const taskDocRef = doc(db, path, taskId);
        const taskDoc = await getDoc(taskDocRef);

        if (taskDoc.exists()) {
            return { id: taskDoc.id, ...taskDoc.data() };
        } else {
            throw new Error("Task not found");
        }
    } catch (error) {
        console.error("Error fetching task by ID:", error);
        throw error;
    }
};

export const updateTask = async (path, taskId, updatedTask, comments) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        const db = getFirestore();
        const taskDocRef = doc(db, path, taskId);
        const taskListDocRef = doc(db, 'task_list', taskId);

        if (comments) {
            // Update comments in the original location
            await updateDoc(taskDocRef, {
                comments: arrayUnion(comments)
            });

            // Update comments in the task_list collection
            await updateDoc(taskListDocRef, {
                comments: arrayUnion(comments)
            });
        } else if (updatedTask) {
            // Update task in the original location
            await updateDoc(taskDocRef, updatedTask);

            // Update or create task in the task_list collection
            await setDoc(taskListDocRef, updatedTask, { merge: true });
        }
    } catch (error) {
        console.error("Error updating task:", error);
        throw error;
    }
};

export const deleteTask = async (collectionPath, taskId) => {
    try {
        const db = getFirestore();
        const taskRef = doc(db, collectionPath, taskId);
        await deleteDoc(taskRef);
    } catch (error) {
        console.error("Error deleting task:", error);
        throw error;
    }
};
