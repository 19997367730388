import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { FaEye, FaTrash, FaSearch, FaUsers, FaUserTie, FaUserCog, FaUserNinja } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api';
import Loader from '../components/common/Loader';
import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import Pagination from '../components/common/Pagination';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalManagers, setTotalManagers] = useState(0);
  const [totalDevelopers, setTotalDevelopers] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get(`api/users`);
      const filteredUsers = response.data.filter(user => user.role.toLowerCase().trim() !== 'admin');
      setUsers(filteredUsers);
      setTotalUsers(filteredUsers.length);
      setTotalLeads(filteredUsers.filter(user => user.role.toLowerCase().trim() === 'lead').length);
      setTotalManagers(filteredUsers.filter(user => user.role.toLowerCase().trim() === 'manager').length);
      setTotalDevelopers(filteredUsers.filter(user => user.role.toLowerCase().trim() === 'developer').length);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error(error.response?.data?.error || 'Failed to fetch users');
      setLoading(false);
    }
  };

  const handleDeleteClick = (userId) => {
    setUserToDelete(userId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (userToDelete) {
      try {
        setLoading(true)
        await api.delete(`api/users/${userToDelete}`);
        toast.success('User deleted successfully');
        fetchUsers(); // Refresh the user list
      } catch (error) {
        setLoading(false)
        console.error('Error deleting user:', error);
        toast.error(error.response?.data?.error || 'Failed to delete user');
      } finally {
        setIsDeleteModalOpen(false);
        setUserToDelete(null);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setUserToDelete(null);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <><Layout>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>Admin Dashboard</h1>
          </div>
          <div className='p-6 bg-gray-50 h-[calc(100vh-4rem-3.5rem)] overflow-y-auto'>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
              <div className="bg-white p-4 rounded-lg shadow">
                <div className="flex items-center">
                  <FaUsers className="text-3xl text-orange-500 mr-3" />
                  <div>
                    <h2 className="text-sm font-semibold text-gray-600">Total Users</h2>
                    <p className="text-2xl font-bold text-orange-500">{totalUsers}</p>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <div className="flex items-center">
                  <FaUserTie className="text-3xl text-blue-500 mr-3" />
                  <div>
                    <h2 className="text-sm font-semibold text-gray-600">Total Leads</h2>
                    <p className="text-2xl font-bold text-blue-500">{totalLeads}</p>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <div className="flex items-center">
                  <FaUserCog className="text-3xl text-green-500 mr-3" />
                  <div>
                    <h2 className="text-sm font-semibold text-gray-600">Total Managers</h2>
                    <p className="text-2xl font-bold text-green-500">{totalManagers}</p>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <div className="flex items-center">
                  <FaUserNinja className="text-3xl text-purple-500 mr-3" />
                  <div>
                    <h2 className="text-sm font-semibold text-gray-600">Total Developers</h2>
                    <p className="text-2xl font-bold text-purple-500">{totalDevelopers}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">User List</h2>
                <div className="flex items-center">
                  <FaSearch className="text-gray-400 mr-2" />
                  <input
                    type="text"
                    placeholder="Search users..."
                    className="border rounded px-2 py-1"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">S.No</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {currentUsers.map((user, index) => (
                        <tr key={user.email}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {indexOfFirstUser + index + 1}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">{`${user.first_name} ${user.last_name}`}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">{user.email}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {capitalizeFirstLetter(user.role)}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <Link to={`/user/${user.email}`} className="text-blue-600 hover:text-blue-900 mr-4">
                              <FaEye className="inline" />
                            </Link>
                            <button onClick={() => handleDeleteClick(user.email)} className="text-red-600 hover:text-red-900">
                              <FaTrash className="inline" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={Math.ceil(filteredUsers.length / usersPerPage)}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!loading && <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm} />}
    </Layout >
    </>
  );
};

export default AdminDashboard;