import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes, FaSort, FaTrash, FaEye } from 'react-icons/fa';
import Layout from '../common/Layout';
import Select from 'react-select';
import { getDataWithQuery, getAllData, deleteTask, fetchTaskLifestyleConfig } from '../../firebase/firetstore';
import { auth } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';
import SideMenu from '../common/SideMenu';
import { FaFilter, FaEdit, FaSortUp, FaSortDown } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../common/Loader';
import Pagination from '../common/Pagination';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const LeadTaskList = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredDevelopers, setFilteredDevelopers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [tasksPerPage] = useState(10);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const [activeDateFilter, setActiveDateFilter] = useState('today');
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
    const [filterSidebarPosition, setFilterSidebarPosition] = useState('-translate-x-full');
    const [dateError, setDateError] = useState('');

    const clearAllFilters = () => {
        setSelectedProjects([]);
        setSelectedUsers([]);
        setSelectedStatuses([]);
        setFromDate('');
        setToDate('');
        setActiveDateFilter('today');
        // Reset the date range
        handleDateFilterChange('today');
        // Reset the filtered users
        filterUsersByProjects([]);
        // Force a re-render of the Select components
        setProjects([...projects]);
        setFilteredUsers([...filteredUsers]);
        setStatuses([...statuses]);
    };

    const getDateRange = (filter) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const weekStart = new Date(today);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);

        switch (filter) {
            case 'yesterday':
                return {
                    start: yesterday,
                    end: new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999)
                };
            case 'today':
                return {
                    start: today,
                    end: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)
                };
            case 'this_week':
                return {
                    start: weekStart,
                    end: weekEnd
                };
            default:
                return null;
        }
    };
    const DateFilterTabs = ({ activeDateFilter, handleDateFilterChange, className = "", isOutside = false }) => {
        const baseButtonClass = "px-4 py-2 rounded-full font-medium transition-colors duration-200";
        const activeClass = "bg-orange-500 text-white shadow-md";
        const inactiveClass = "bg-gray-200 text-gray-700 hover:bg-gray-300";

        const getButtonClass = (filter) => {
            return `${baseButtonClass} ${activeDateFilter === filter ? activeClass : inactiveClass} ${isOutside ? 'text-sm md:text-base' : 'text-xs'}`;
        };

        return (
            <div className={`flex flex-wrap gap-2 ${className} ${isOutside ? 'p-3 rounded-lg' : 'bg-gray-50 p-2 rounded-md'}`}>
                <button
                    className={getButtonClass('today')}
                    onClick={() => handleDateFilterChange('today')}
                >
                    Today
                </button>
                <button
                    className={getButtonClass('yesterday')}
                    onClick={() => handleDateFilterChange('yesterday')}
                >
                    Yesterday
                </button>
                <button
                    className={getButtonClass('this_week')}
                    onClick={() => handleDateFilterChange('this_week')}
                >
                    This Week
                </button>
                <button
                    className={getButtonClass('all')}
                    onClick={() => handleDateFilterChange('all')}
                >
                    All
                </button>
            </div>
        );
    };

    const handleDateFilterChange = (filter) => {
        setActiveDateFilter(filter);
        setDateError(''); // Clear any previous errors

        if (filter === 'all') {
            setFromDate('');
            setToDate('');
            setMinDate('');
            setMaxDate('');
        } else {
            const range = getDateRange(filter);
            if (range) {
                const startDate = new Date(range.start.getTime() - range.start.getTimezoneOffset() * 60000);
                const endDate = new Date(range.end.getTime() - range.end.getTimezoneOffset() * 60000);
                const newFromDate = startDate.toISOString().split('T')[0];
                const newToDate = endDate.toISOString().split('T')[0];

                // Validate dates
                if (newFromDate > newToDate) {
                    setDateError('From date cannot be later than To date');
                    return;
                }

                setFromDate(newFromDate);
                setToDate(newToDate);

                if (filter === 'this_week') {
                    setMinDate(newFromDate);
                    setMaxDate(newToDate);
                } else {
                    setMinDate('');
                    setMaxDate('');
                }
            }
        }
    };

    const handleFromDateChange = (e) => {
        const newFromDate = e.target.value;
        setFromDate(newFromDate);
        setDateError('');

        if (toDate && newFromDate > toDate) {
            setDateError('From date cannot be later than To date');
        }
    };

    const handleToDateChange = (e) => {
        const newToDate = e.target.value;
        setToDate(newToDate);
        setDateError('');

        if (fromDate && newToDate < fromDate) {
            setDateError('To date cannot be earlier than From date');
        }
    };

    const customSelectStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#d1d5db',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#9ca3af',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#f97316' : state.isFocused ? '#fed7aa' : null,
            color: state.isSelected ? 'white' : '#1f2937',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#fed7aa',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#9a3412',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#9a3412',
            '&:hover': {
                backgroundColor: '#f97316',
                color: 'white',
            },
        }),
    };

    const handleSortClick = () => {
        const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        setSortOrder(newSortOrder);
        const sortedTasks = [...tasks].sort((a, b) => {
            if (newSortOrder === 'asc') {
                return new Date(a.created_at.seconds * 1000) - new Date(b.created_at.seconds * 1000);
            } else {
                return new Date(b.created_at.seconds * 1000) - new Date(a.created_at.seconds * 1000);
            }
        });
        setTasks(sortedTasks);
    };

    const handleDateFilter = () => {
        return tasks.filter(task => {
            const createdDate = new Date(task.created_at.seconds * 1000);
            const updatedDate = task.updated_at ? new Date(task.updated_at.seconds * 1000) : createdDate;
            const latestDate = new Date(Math.max(createdDate, updatedDate));
            latestDate.setHours(0, 0, 0, 0);

            let from = fromDate ? new Date(fromDate) : new Date(0);
            from.setHours(0, 0, 0, 0);
            let to = toDate ? new Date(toDate) : new Date();
            to.setHours(23, 59, 59, 999);
            return latestDate >= from && latestDate <= to;
        });
    };

    const filteredTasks = handleDateFilter()
        .filter(task =>
            (selectedProjects.length === 0 || selectedProjects.includes(task.project_name)) &&
            (selectedUsers.length === 0 || selectedUsers.includes(task.assigned_to)) &&
            (selectedStatuses.length === 0 || selectedStatuses.includes(task.status))
        )
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.created_at.seconds * 1000) - new Date(b.created_at.seconds * 1000);
            } else {
                return new Date(b.created_at.seconds * 1000) - new Date(a.created_at.seconds * 1000);
            }
        });

    const indexOfLastTask = currentPage * tasksPerPage;
    const indexOfFirstTask = indexOfLastTask - tasksPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleDeleteClick = (taskId) => {
        setTaskToDelete(taskId);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (taskToDelete) {
            setIsLoading(true);
            try {
                const id = taskToDelete.toString();
                const taskToDeleteDetails = tasks.find(task => task.id === taskToDelete);

                // Delete from global task list
                await deleteTask('task_list', id);

                // Delete from lead's collection
                const userEmail = auth.currentUser.email;
                await deleteTask(`user_details/${userEmail}/task_list`, id);

                // Delete from developer's collection if assigned
                if (taskToDeleteDetails.assigned_to && taskToDeleteDetails.assigned_to !== userEmail) {
                    await deleteTask(`user_details/${taskToDeleteDetails.assigned_to}/task_list`, id);
                }

                setTasks(tasks.filter(task => task.id !== taskToDelete));
                setIsLoading(false);
                toast.success("Task deleted successfully!");
            } catch (error) {
                setIsLoading(false);
                console.error("Error deleting task:", error);
                toast.error("Task deletion failed!");
            } finally {
                setIsDeleteModalOpen(false);
                setTaskToDelete(null);
            }
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
        setTaskToDelete(null);
    };

    const handleSortByCreatedAt = () => {
        const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        setSortOrder(newSortOrder);
        const sortedTasks = [...tasks].sort((a, b) => {
            if (newSortOrder === 'asc') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            } else {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
        });
        setTasks(sortedTasks);
    };


    useEffect(() => {
        fetchTasks();
        fetchFilters();
        fetchUsers();
        if (user && user.projects) {
            setProjects(user.projects);
        }
        handleDateFilterChange('today')
    }, [user]);

    useEffect(() => {
        setFilterSidebarPosition(isFilterSidebarOpen ? 'translate-x-0' : '-translate-x-full');
    }, [isFilterSidebarOpen]);

    const fetchFilters = async () => {
        try {
            const collection = "configs";
            const document = "task_life_cycle";
            const lifestyles = await fetchTaskLifestyleConfig(collection, document);
            setStatuses(lifestyles?.task_life_cycle || []);
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    };

    const fetchTasks = async () => {
        setIsLoading(true);
        try {
            const collectionPath = 'task_list';
            let fetchedTasks = [];

            // Fetch tasks for all projects of the logged-in user
            const projectTasks = await Promise.all(user.projects.map(async (project) => {
                const projectTasks = await getDataWithQuery(collectionPath, [
                    { field: 'project_name', operator: '==', value: project }
                ]);
                return projectTasks;
            }));

            fetchedTasks = projectTasks.flat();

            setTasks(fetchedTasks);

            // Extract unique developers
            const uniqueDevelopers = [...new Set(fetchedTasks.map(task => task.assigned_to))];
            setFilteredDevelopers(uniqueDevelopers);
        } catch (error) {
            console.error("Error fetching tasks:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUsers = async () => {
        try {
            const db = getFirestore();
            const usersCollection = collection(db, 'user_details');
            const usersSnapshot = await getDocs(usersCollection);

            const users = usersSnapshot.docs.map(doc => ({
                email: doc.id,
                ...doc.data()
            })).filter(user => user.role === 'developer' || user.role === 'lead');

            setAllUsers(users);
            setFilteredUsers(users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const filterUsersByProjects = (selectedProjects) => {
        if (selectedProjects.length === 0) {
            setFilteredUsers(allUsers);
        } else {
            const filtered = allUsers.filter(user =>
                user.projects && user.projects.some(project => selectedProjects.includes(project))
            );
            setFilteredUsers(filtered);
        }
    };

    // const handleTabChange = (tab) => {
    //     // setActiveTab(tab);
    //     setTasks([]); // Clear tasks when changing tabs
    //     setSelectedProjects([]);
    //     setSelectedDevelopers([]);
    //     setSelectedStatuses([]);
    // };

    const handleTaskClick = (taskId) => {
        navigate(`/task/${taskId}`);
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return (
        <Layout>
            <style>
                {`
                    .date-input-grey::-webkit-datetime-edit { color: #999; }
                    .date-input-grey::-moz-placeholder { color: #999; }
                    .date-input-grey:-ms-input-placeholder { color: #999; }
                    .date-input-grey::placeholder { color: #999; }
                `}
            </style>
            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                draggable
                theme='light'
            />
            <div className="flex w-full h-[calc(100vh-4rem)]">
                <SideMenu />
                <div className="flex-1 overflow-hidden relative">
                    <div className="h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600">
                        <h1 className="text-2xl font-bold text-white">Task List</h1>
                    </div>
                    <div className="p-6 bg-gray-50 h-[calc(100vh-4rem-3.5rem)] overflow-y-auto">
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center">
                                <button
                                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                                    className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200 mr-4"
                                >
                                    <FaBars className="text-orange-500" />
                                </button>
                                <DateFilterTabs
                                    activeDateFilter={activeDateFilter}
                                    handleDateFilterChange={handleDateFilterChange}
                                    isOutside={true}
                                />
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="flex justify-center items-center h-full">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <div className="overflow-x-auto shadow-md rounded-lg">
                                    <table className="w-full divide-y divide-gray-200">
                                        <thead className="bg-gradient-to-r from-gray-800 to-gray-900">
                                            <tr>
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">Id</th>
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider w-1/3">Title</th>
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">Status</th>
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">
                                                    <div>Estimate</div>
                                                    <div className="text-xs font-normal normal-case">(hours)</div>
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">Project</th>
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">Owner</th>
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">
                                                    Created At
                                                    <button onClick={handleSortByCreatedAt} className="ml-2 focus:outline-none">
                                                        <FaSort className={`inline ${sortOrder === 'desc' ? 'text-gray-400' : 'text-white'}`} />
                                                    </button>
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {currentTasks.map((task, index) => (
                                                <tr key={task.id} className="hover:bg-gray-50 transition-colors">
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        <Link
                                                            to={`/task/${task.id}`}
                                                            className="text-blue-600 hover:text-blue-800 hover:underline"
                                                        >
                                                            {task.id}
                                                        </Link>
                                                    </td>
                                                    <td className="px-4 py-4">
                                                        <div className="text-sm font-semibold text-gray-900 line-clamp-2 break-words" title={task.title}>
                                                            {task.title}
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-4 whitespace-nowrap">
                                                        <span className={`px-2 py-1 inline-flex text-xs leading-5 font-bold rounded-full ${task.status === 'completed' ? 'bg-green-100 text-green-800' :
                                                            task.status === 'in progress' ? 'bg-yellow-100 text-yellow-800' :
                                                                'bg-red-100 text-red-800'
                                                            }`}>
                                                            {task.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{task.estimation}</td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{capitalizeFirstLetter(task.project_name)}</td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                                                        {task.assigned_to_first_name && task.assigned_to_last_name
                                                            ? `${task.assigned_to_first_name} ${task.assigned_to_last_name.charAt(0)}`
                                                            : task.assigned_to}
                                                    </td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{new Date(task.created_at.seconds * 1000).toLocaleDateString()}</td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium">
                                                        <button
                                                            onClick={() => handleTaskClick(task.id)}
                                                            className="text-indigo-600 hover:text-indigo-900 mr-3"
                                                            title="View Task"
                                                        >
                                                            <FaEye size={16} />
                                                        </button>
                                                        {(task.creator_email === user.email || task.assigned_to === user.email) && (
                                                            <button
                                                                onClick={() => handleDeleteClick(task.id)}
                                                                className="text-red-600 hover:text-red-900"
                                                                title="Delete Task"
                                                            >
                                                                <FaTrash size={16} />
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                {filteredTasks.length > 0 ? (
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(filteredTasks.length / tasksPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                ) : (
                                    <div className="flex flex-col justify-center items-center mt-8">
                                        <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
                                        </svg>
                                        <p className="text-xl text-gray-500 font-semibold">No Tasks Found</p>
                                        <p className="text-sm text-gray-400 mt-2">There are no tasks available at the moment.</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className={`absolute top-14 left-0 w-80 h-[calc(100vh-4rem-3.5rem)] bg-white shadow-lg overflow-y-auto p-6 transition-all duration-300 ease-in-out transform ${filterSidebarPosition}`}>
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-semibold">Filters</h2>
                            <button
                                onClick={() => setIsFilterSidebarOpen(false)}
                                className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
                            >
                                <FaTimes className="text-gray-500 hover:text-gray-700" />
                            </button>
                        </div>
                        <div className="space-y-4">
                            <div>
                                <h3 className="font-medium mb-2">Date Range</h3>
                                <DateFilterTabs
                                    activeDateFilter={activeDateFilter}
                                    handleDateFilterChange={handleDateFilterChange}
                                    className="flex-wrap"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">From</label>
                                <input
                                    type="date"
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                    className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent date-input-grey"
                                    disabled={activeDateFilter === 'yesterday' || activeDateFilter === 'today'}
                                    min={minDate}
                                    max={maxDate}
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">To</label>
                                <input
                                    type="date"
                                    value={toDate}
                                    onChange={handleToDateChange}
                                    className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent date-input-grey"
                                    disabled={activeDateFilter === 'yesterday' || activeDateFilter === 'today'}
                                    min={minDate}
                                    max={maxDate}
                                />
                            </div>
                            {dateError && (
                                <div className="text-red-500 text-sm mt-2">
                                    {dateError}
                                </div>
                            )}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">Projects</label>
                                <Select
                                    isMulti
                                    options={projects.map(project => ({ value: project, label: capitalizeFirstLetter(project) }))}
                                    value={selectedProjects.map(project => ({ value: project, label: capitalizeFirstLetter(project) }))}
                                    onChange={(selectedOptions) => {
                                        const selectedProjects = selectedOptions.map(option => option.value);
                                        setSelectedProjects(selectedProjects);
                                        filterUsersByProjects(selectedProjects);
                                    }}
                                    placeholder="Select Projects"
                                    styles={customSelectStyles}
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">Users</label>
                                <Select
                                    isMulti
                                    options={filteredUsers.map(user => ({
                                        value: user.email,
                                        label: `${user.first_name} ${user.last_name.charAt(0)} (${user.role})`
                                    }))}
                                    value={selectedUsers.map(email => {
                                        const user = filteredUsers.find(u => u.email === email);
                                        return {
                                            value: email,
                                            label: user ? `${user.first_name} ${user.last_name.charAt(0)} (${user.role})` : email
                                        };
                                    })}
                                    onChange={(selectedOptions) => setSelectedUsers(selectedOptions.map(option => option.value))}
                                    placeholder="Select Users"
                                    styles={customSelectStyles}
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">Statuses</label>
                                <Select
                                    isMulti
                                    options={statuses.map(status => ({ value: status, label: status }))}
                                    value={selectedStatuses.map(status => ({ value: status, label: status }))}
                                    onChange={(selectedOptions) => setSelectedStatuses(selectedOptions.map(option => option.value))}
                                    placeholder="Select Statuses"
                                    styles={customSelectStyles}
                                />
                            </div>
                            <div className="mt-6">
                                <button
                                    onClick={clearAllFilters}
                                    className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                                >
                                    Clear All Filters
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* )} */}
                </div>
            </div>
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
            />
        </Layout>
    );
};

export default LeadTaskList;