import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import api from '../api';
import Select from 'react-select';
import { FaUser, FaTrash } from 'react-icons/fa';
import Loader from '../components/common/Loader';

const CreateUser = () => {
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    role: '',
    manager_email: '',
    projects: []
  });
  const [userRoles, setUserRoles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserRoles();
    fetchProjects();
    fetchManagers();
  }, []);

  const fetchUserRoles = async () => {
    try {
      const db = getFirestore();
      const rolesDoc = await getDoc(doc(db, 'configs', 'user_roles'));
      if (rolesDoc.exists()) {
        setUserRoles(rolesDoc.data().user_roles);
      }
    } catch (error) {
      console.error('Error fetching user roles:', error);
      toast.error('Failed to fetch user roles');
    }
  };

  const fetchProjects = async () => {
    try {
      const db = getFirestore();
      const projectsCollection = collection(db, '/projects');
      const projectsSnapshot = await getDocs(projectsCollection);
      const projectsList = projectsSnapshot.docs
        .filter(doc => {
          const projectStatus = doc.data().project_status;
          return !projectStatus || projectStatus.toLowerCase() !== 'in-active';
        })
        .map(doc => ({
          value: doc.data().project_name,
          label: doc.data().project_name,
          ...doc.data()
        }));
      setProjects(projectsList);
    } catch (error) {
      console.error('Error fetching active projects:', error);
      toast.error('Failed to fetch active projects');
    }
  };

  const fetchManagers = async () => {
    try {
      const db = getFirestore();
      const usersCollection = collection(db, 'user_details');
      const managersQuery = query(usersCollection, where('role', '==', 'manager'));
      const managersSnapshot = await getDocs(managersQuery);
      const managersList = managersSnapshot.docs.map(doc => ({
        value: doc.data().email,
        label: `${doc.data().first_name} ${doc.data().last_name} (${doc.data().email})`,
        ...doc.data()
      }));
      setManagers(managersList);
    } catch (error) {
      console.error('Error fetching managers:', error);
      toast.error('Failed to fetch managers');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'role' && value === 'manager') {
      setUserDetails({ ...userDetails, [name]: value, manager_email: '' });
    } else {
      setUserDetails({ ...userDetails, [name]: value });
    }
  };

  const handleProjectsChange = (selectedOptions) => {
    const selectedProjects = selectedOptions.map(option => option.value);
    setUserDetails({ ...userDetails, projects: selectedProjects });
  };

  const handleManagerChange = (selectedOption) => {
    setUserDetails({ ...userDetails, manager_email: selectedOption ? selectedOption.value : '' });
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    const allowedTypes = /jpeg|jpg|png/;
    const fileType = file?.type.split('/')[1];

    if (!allowedTypes.test(fileType)) {
      toast.error('Only JPEG, JPG, and PNG files are allowed');
      return;
    }
    if (file && file.size <= 2 * 1024 * 1024) { // 2MB limit
      setSelectedFile(file);
      setProfileImage(URL.createObjectURL(file));
    } else {
      toast.error('Image size should be less than 2MB');
    }
  };

  const handleUpload = async (email) => {
    if (!selectedFile || !email) {
      toast.error('Please select an image and provide an email address');
      return;
    }
    console.log(email, " userDetails.email")
    setIsUploading(true);
    const formData = new FormData();
    formData.append('profile_image', selectedFile);
    formData.append('email', userDetails.email);

    try {
      const response = await api.post('api/users/upload-profile-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Image upload response:', response.data);
      toast.success('Image uploaded successfully');
      // Store the returned image URL in your state
      setProfileImageUrl(response.data.imageUrl);
      // Update the profile image preview
      setProfileImage(response.data.imageUrl);
      setSelectedFile(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    } finally {
      setIsUploading(false);
    }
  };


  const handleCancelUpload = () => {
    setSelectedFile(null);
    setProfileImage(null);
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setProfileImage(null);
    setProfileImageUrl(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (userDetails.role !== 'manager' && !userDetails.manager_email) {
        toast.error('Manager email is required for non-manager roles');
        return;
      }
      await api.post('api/users', userDetails);
      toast.success('User created successfully');
      if (selectedFile) {
        await handleUpload(userDetails.email)
      }
      navigate('/dashboard');
    } catch (error) {
      console.error('Error creating user:', error);
      toast.error(error.response?.data?.error || 'Failed to create user');
    }
  };

  return (
    <Layout>
      {isLoading ? <Loader />
        : <>
          <ToastContainer position="top-right" autoClose={3000} />
          <div className="flex w-full h-[calc(100vh-4rem)]">
            <SideMenu />
            <div className="flex-1 flex flex-col">
              <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
                <h1 className='font-semibold text-xl text-white'>Create User</h1>
              </div>
              <div className='p-6 bg-gray-50 flex-1 overflow-y-auto'>
                <div className="bg-white p-6 rounded-lg shadow flex">
                  <div className="w-3/5 pr-4">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                          Email
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="email"
                          type="email"
                          name="email"
                          value={userDetails.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                          Password
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="password"
                          type="password"
                          name="password"
                          value={userDetails.password}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
                          First Name
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="first_name"
                          type="text"
                          name="first_name"
                          value={userDetails.first_name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">
                          Last Name
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="last_name"
                          type="text"
                          name="last_name"
                          value={userDetails.last_name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                          Role
                        </label>
                        <select
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="role"
                          name="role"
                          value={userDetails.role}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select a role</option>
                          {userRoles.map((role) => (
                            <option key={role} value={role}>
                              {role}
                            </option>
                          ))}
                        </select>
                      </div>
                      {userDetails.role !== 'manager' && (
                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="manager_email">
                            Manager
                          </label>
                          <Select
                            options={managers}
                            onChange={handleManagerChange}
                            value={managers.find(manager => manager.value === userDetails.manager_email)}
                            placeholder="Select a manager"
                            isClearable
                            isSearchable
                            className="basic-single"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="projects">
                          Projects
                        </label>
                        <Select
                          isMulti
                          name="projects"
                          options={projects}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={projects.filter(project => userDetails.projects.includes(project.value))}
                          onChange={handleProjectsChange}
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <button
                          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          type="submit"
                        >
                          Create User
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="w-2/5 pl-6 border-l border-gray-200">
                    <h2 className="text-lg font-semibold mb-4">User Image</h2>
                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center">
                      <label htmlFor="user-image-upload" className="cursor-pointer mb-4">
                        {profileImage ? (
                          <img src={profileImage} alt="User" className="w-32 h-32 rounded-full object-cover" />
                        ) : (
                          <FaUser className="w-32 h-32 text-gray-400" />
                        )}
                      </label>
                      <input
                        id="user-image-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileSelect}
                      />
                      {!selectedFile && !profileImage && (
                        <>
                          <p className="text-sm text-gray-500 mt-2">Click to upload an image</p>
                          <p className="text-xs text-gray-400 mt-1">Max size: 2MB</p>
                        </>
                      )}
                      {(selectedFile || profileImage) && (
                        <button
                          onClick={handleRemoveImage}
                          className="mt-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                        >
                          <FaTrash className="mr-2" /> Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}

    </Layout>
  );
};

export default CreateUser;