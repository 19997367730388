import axios from 'axios';
import CryptoJS from 'crypto-js';

const API_BASE_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';
const HMAC_SECRET = process.env.REACT_APP_HMAC_SECRET;

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const { method, url, data } = config;
  
  // Create the string to be signed
  const timestamp = Date.now().toString();
  const bodyString = data ? JSON.stringify(data) : '';
  const stringToSign = `${method.toUpperCase()}/${url}${bodyString}${timestamp}`;

  // Calculate HMAC
  const hmac = CryptoJS.HmacSHA256(stringToSign, HMAC_SECRET);
  const signature = CryptoJS.enc.Base64.stringify(hmac);

  // Add headers
  config.headers['x-hmac-signature'] = signature;
  config.headers['x-timestamp'] = timestamp;

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;