import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../common/Layout';
import { getTaskById, updateTask, fetchTaskLifestyleConfig, deleteTask } from '../../firebase/firetstore';
import { auth } from '../../firebase/firebaseConfig';
import SideMenu from '../common/SideMenu';
import { useAuth } from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../common/Loader';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';

const LeadTaskDetails = () => {
    const { taskId } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [task, setTask] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedTask, setEditedTask] = useState(null);
    const [statusOptions, setStatusOptions] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [errors, setErrors] = useState({});
    const [isOwnTask, setIsOwnTask] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!editedTask.title.trim()) newErrors.title = "Title is required";
        if (!editedTask.description.trim()) newErrors.description = "Description is required";
        if (!editedTask.status) newErrors.status = "Status is required";
        if (!editedTask.estimation) newErrors.estimation = "Estimate is required";
        if (isNaN(Number(editedTask.estimation))) newErrors.estimation = "Estimate must be a number";
        if (!editedTask.project_name.trim()) newErrors.project_name = "Project Name is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        const fetchTaskAndConfig = async () => {
            try {
                setIsLoading(true);
                const collection = "configs";
                const document = "task_life_cycle";
                const path = `task_list`;
                const [taskData, configData] = await Promise.all([
                    getTaskById(taskId, path),
                    fetchTaskLifestyleConfig(collection, document)
                ]);
                setTask(taskData);
                setEditedTask(taskData);
                setStatusOptions(configData.task_life_cycle || []);
                setIsOwnTask(taskData.creator_email === auth.currentUser.email || taskData.assigned_to === auth.currentUser.email);
            } catch (error) {
                console.error("Error fetching task or config:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTaskAndConfig();
    }, [taskId]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleDeleteClick = () => {
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setIsLoading(true);
        try {
            const id = taskId.toString();
            const userTaskPath = `user_details/${task.creator_email}/task_list`;
            const globalTaskPath = 'task_list';
            const developerTaskPath = `user_details/${editedTask.assigned_to}/task_list`;

            await deleteTask(userTaskPath, id);
            await deleteTask(globalTaskPath, id);
            if (editedTask.assigned_to && editedTask.assigned_to !== task.creator_email) {
                await deleteTask(developerTaskPath, id);
            }
            setIsLoading(false);
            toast.success("Task deleted successfully!");
            navigate('/task-list');
        } catch (error) {
            setIsLoading(false);
            console.error("Error deleting task:", error);
            toast.error('Failed to delete the task. Please try again.');
        } finally {
            setIsDeleteModalOpen(false);
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    };

    const handleSave = async () => {
        if (validateForm()) {
            setIsLoading(true);
            try {
                const updatedTask = {
                    ...editedTask,
                    updated_at: new Date()
                };

                const userTaskPath = `user_details/${task.creator_email}/task_list`;
                const globalTaskPath = 'task_list';
                const developerTaskPath = `user_details/${updatedTask.assigned_to}/task_list`;

                await updateTask(userTaskPath, taskId, updatedTask, "");
                await updateTask(globalTaskPath, taskId, updatedTask, "");

                if (updatedTask.assigned_to && updatedTask.assigned_to !== task.creator_email) {
                    await updateTask(developerTaskPath, taskId, updatedTask, "");
                }

                setTask(updatedTask);
                toast.success("Task updated successfully!");
                setIsEditing(false);
                setIsLoading(false);
                setErrors({});
            } catch (error) {
                setIsLoading(false);
                toast.error("Failed to update task!");
                console.error("Error updating task:", error);
            }
        }
    };

    const handleCancel = () => {
        setEditedTask(task);
        setIsEditing(false);
        setErrors({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'estimation') {
            const numericValue = value.replace(/[^0-9]/g, '');
            setEditedTask(prev => ({ ...prev, [name]: numericValue }));
        } else {
            setEditedTask(prev => ({ ...prev, [name]: value }));
        }
        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const handleCommentSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        if (!newComment) {
            setIsLoading(false);
            toast.error("Comment cannot be empty.");
            return;
        }

        if (!user) {
            console.error("User not authenticated");
            toast.error("You must be authenticated to add a comment.");
            return;
        }

        const comment = {
            creator_email: user.email || '',
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            comment: newComment,
            created_at: new Date()
        };
        try {
            const userTaskPath = `user_details/${task.creator_email}/task_list`;
            const globalTaskPath = 'task_list';
            const developerTaskPath = `user_details/${editedTask.assigned_to}/task_list`;

            await updateTask(globalTaskPath, taskId, "", comment);
            await updateTask(userTaskPath, taskId, "", comment);

            if (editedTask.assigned_to && editedTask.assigned_to !== task.creator_email) {
                await updateTask(developerTaskPath, taskId, "", comment);
            }

            setTask(prevTask => ({
                ...prevTask,
                comments: [...(prevTask.comments || []), comment]
            }));
            setNewComment('');
            setIsLoading(false);
            toast.success("Comment added successfully!");
        } catch (error) {
            setIsLoading(false);
            console.error("Error adding comment:", error);
            toast.error("Failed to add comment. Please try again.");
        }
    };

    const formatDate = (dateInput) => {
        let date;
        if (dateInput instanceof Date) {
            date = dateInput;
        } else if (typeof dateInput === 'object' && dateInput.seconds) {
            date = new Date(dateInput.seconds * 1000);
        } else if (typeof dateInput === 'string' || typeof dateInput === 'number') {
            date = new Date(dateInput);
        } else {
            console.error('Invalid date format:', dateInput);
            return 'Invalid Date';
        }

        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateInput);
            return 'Invalid Date';
        }

        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    };

    const handleBackClick = () => {
        navigate('/task-list');
    };
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return (
        <Layout>
            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                draggable
                theme='light'
            />
            <div className="flex w-full h-[calc(100vh-4rem)]">
                <SideMenu />
                <div className="flex-1 overflow-hidden">
                    {isLoading ? (
                        <div className="flex justify-center items-center h-full">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <div className="h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600">
                                <h2 className="text-2xl font-bold text-white">Task Details</h2>
                            </div>
                            <div className="p-6 bg-gray-50 h-[calc(100vh-4rem-3.5rem)] overflow-y-auto">
                                <div className="bg-white rounded-lg shadow-lg p-6">
                                    <div className="flex items-center justify-between mb-6">
                                        <div className="flex items-center">
                                            <button
                                                onClick={handleBackClick}
                                                className="mr-4 text-orange-500 hover:text-orange-600 focus:outline-none"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                                </svg>
                                            </button>
                                            <h2 className="text-2xl font-bold text-gray-900">{task?.id}</h2>
                                        </div>
                                        <div className="flex space-x-2">
                                            {isOwnTask && !isEditing && (
                                                <>
                                                    <button
                                                        onClick={handleEdit}
                                                        className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        onClick={handleDeleteClick}
                                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="space-y-6">
                                        {/* ID and Project Name in one line */}
                                        <div className="flex space-x-4">
                                            <div className="w-1/2">
                                                <label className="block text-sm font-medium text-gray-700 mb-1">ID</label>
                                                <input
                                                    type="text"
                                                    value={task.id}
                                                    disabled
                                                    className="w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md focus:outline-none"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <label className="block text-sm font-medium text-gray-700 mb-1">Project</label>
                                                <input
                                                    type="text"
                                                    value={capitalizeFirstLetter(task.project_name)}
                                                    disabled
                                                    className="w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md focus:outline-none"
                                                />
                                            </div>
                                        </div>

                                        {/* Title in one line */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
                                            <input
                                                type="text"
                                                name="title"
                                                value={isEditing ? editedTask.title : task.title}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                                className={`w-full px-3 py-2 ${isEditing ? 'bg-white' : 'bg-gray-100'} text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 ${errors.title ? 'border-red-500' : ''}`}
                                            />
                                            {errors.title && <p className="text-red-500 text-xs mt-1">{errors.title}</p>}
                                        </div>

                                        {/* Description in one line */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                                            <textarea
                                                name="description"
                                                value={isEditing ? editedTask.description : task.description}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                                rows="3"
                                                className={`w-full px-3 py-2 ${isEditing ? 'bg-white' : 'bg-gray-100'} text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 ${errors.description ? 'border-red-500' : ''}`}
                                            />
                                            {errors.description && <p className="text-red-500 text-xs mt-1">{errors.description}</p>}
                                        </div>

                                        {/* Status and Estimation in one line */}
                                        <div className="flex space-x-4">
                                            <div className="w-1/2">
                                                <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                                                {isEditing ? (
                                                    <select
                                                        name="status"
                                                        value={editedTask.status}
                                                        onChange={handleChange}
                                                        className={`w-full px-3 py-2 bg-white text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 ${errors.status ? 'border-red-500' : ''}`}
                                                    >
                                                        {statusOptions.map((status, index) => (
                                                            <option key={index} value={status}>{status}</option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={task.status}
                                                        disabled
                                                        className="w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md focus:outline-none"
                                                    />
                                                )}
                                                {errors.status && <p className="text-red-500 text-xs mt-1">{errors.status}</p>}
                                            </div>
                                            <div className="w-1/2">
                                                <label className="block text-sm font-medium text-gray-700 mb-1">Estimation (hours)</label>
                                                <input
                                                    type="number"
                                                    name="estimation"
                                                    value={isEditing ? editedTask.estimation : task.estimation}
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                    className={`w-full px-3 py-2 ${isEditing ? 'bg-white' : 'bg-gray-100'} text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 ${errors.estimation ? 'border-red-500' : ''}`}
                                                />
                                                {errors.estimation && <p className="text-red-500 text-xs mt-1">{errors.estimation}</p>}
                                            </div>
                                        </div>
                                        {task
                                            && (
                                                <div className="flex space-x-4">
                                                    <div className="w-1/2">
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">Assigned by</label>
                                                        <input
                                                            type="text"
                                                            value={task.creator_email || 'Not assigned'}
                                                            disabled
                                                            className="w-full px-3 py-2 bg-gray-100 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                        />
                                                    </div>
                                                    <div className="w-1/2">
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">Assigned to</label>
                                                        <input
                                                            type="text"
                                                            value={task.assigned_to || 'Not assigned'}
                                                            disabled
                                                            className="w-full px-3 py-2 bg-gray-100 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        {isEditing && (
                                            <div className="flex justify-end space-x-4 mt-6">
                                                <button
                                                    onClick={handleSave}
                                                    className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    onClick={handleCancel}
                                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {/* Comments Section */}
                                    <div className="mt-8 bg-gray-50 p-6 rounded-lg shadow-md">
                                        <h3 className="text-xl font-bold mb-4 text-orange-500">Comments</h3>
                                        <form onSubmit={handleCommentSubmit} className="mb-6">
                                            <textarea
                                                value={newComment}
                                                onChange={(e) => setNewComment(e.target.value)}
                                                className="w-full px-4 py-3 text-gray-800 border border-orange-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-300 focus:border-orange-300 font-medium bg-white shadow-sm"
                                                rows="4"
                                                placeholder="Add a comment..."
                                            ></textarea>
                                            <button
                                                type="submit"
                                                className="mt-3 bg-gradient-to-r from-orange-500 to-orange-600 text-white px-6 py-2 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 font-semibold transition duration-150 ease-in-out"
                                            >
                                                Add Comment
                                            </button>
                                        </form>
                                        {(!task.comments || task.comments.length === 0) && (
                                            <div className="text-center py-8">
                                                <div className="w-12 h-12 text-gray-300 mx-auto mb-3" />
                                                <p className="text-gray-500">No comments yet. Be the first to share your thoughts!</p>
                                            </div>
                                        )}


                                        <div className="space-y-4">
                                            {task.comments && [...task.comments].reverse().map((comment, index) => (
                                                <div key={index} className="bg-white p-4 rounded-lg shadow-sm border-l-4 border-orange-500">
                                                    <div className="flex items-center justify-between mb-2">
                                                        <div>
                                                            <p className="text-sm font-semibold text-orange-600">
                                                                {comment.first_name} {comment.last_name}
                                                            </p>
                                                            <p className="text-xs text-gray-500 opacity-75">
                                                                {comment.creator_email}
                                                            </p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">
                                                            {formatDate(comment.created_at)}
                                                        </p>
                                                    </div>
                                                    <p className="text-gray-800 text-base mt-2">{comment.comment}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
            />
        </Layout>
    );
};

export default LeadTaskDetails;