import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from '../firebase/firebaseConfig';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const db = getFirestore();
        
        // Check if user is admin
        const adminDocRef = doc(db, 'configs', 'admin_details');
        const adminDocSnap = await getDoc(adminDocRef);
        
        if (adminDocSnap.exists() && adminDocSnap.data().email === firebaseUser.email) {
          setUser({
            email: firebaseUser.email,
            role: 'admin',
            ...adminDocSnap.data()
          });
        } else {
          // Fetch user details from user_details collection
          const userDocRef = doc(db, 'user_details', firebaseUser.email);
          const userDocSnap = await getDoc(userDocRef);
          
          if (userDocSnap.exists()) {
            setUser({
              email: firebaseUser.email,
              ...userDocSnap.data()
            });
          } else {
            // If user document doesn't exist, set a default role
            setUser({
              email: firebaseUser.email,
              role: firebaseUser.role // You might want to change this default role
            });
          }
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    user,
    loading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};