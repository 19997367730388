import React from 'react';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm,type,message }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Confirmation</h3>
          <div className="mt-2 px-7 py-3">
            {message && message !== '' ? <>
            <p className="text-sm text-gray-500">
                  {message}
            </p>
            </> : <p className="text-sm text-gray-500">
              Are you sure you want to delete? This action cannot be undone.
            </p>}
          </div>
          <div className="items-center px-4 py-3">
            {type && type === 'project-assign' ? <>

            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-24 mr-2 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
            >
              Assign
            </button>
            </> : <>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-24 mr-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
            >
              Delete
            </button>
            </> }
            
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md w-24 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;