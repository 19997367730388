import React, { useState } from 'react';
import { auth } from '../firebase/firebaseConfig';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Layout from '../components/common/Layout';
import Loader from '../components/common/Loader';
import '../styles/style.css'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  // If user is already logged in, redirect to dashboard
  if (user) {
    navigate('/dashboard');
    return null;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setIsLoading(false);
      setError('Failed to log in. Please check your email and password.');
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }
    setIsLoading(true);
    setError('');
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
    } catch (err) {
      setError('Failed to send password reset email. Please check your email address.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {isLoading ? <Loader /> : <>
        <div className="container">
          <div className="card">
            <h2 className="card-title">Login</h2>
            {error && <p className="error-message">{error}</p>}
            {resetSent && <p className="success-message">Password reset email sent. Please check your inbox.</p>}
            <form onSubmit={handleLogin} className="form">
              <div>
                <label htmlFor="email" className="input-label">Email Address</label>
                <input
                  id="email"
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="input-field"
                />
              </div>
              <div>
                <label htmlFor="password" className="input-label">Password</label>
                <input
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="input-field"
                />
              </div>
              <div>
                <button type="submit" className="btn-primary" disabled={isLoading}>
                  {isLoading ? <Loader /> : 'Sign In'}
                </button>
              </div>
            </form>
            <div className="mt-6 text-center">
              <button onClick={handleResetPassword} className="btn-link" disabled={isLoading}>
                Forgot your password?
              </button>
            </div>
            {isLoading && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75 z-50">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </>}


    </Layout>
  );
};

export default Login;