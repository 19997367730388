import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { useAuth } from '../context/AuthContext';
import { FaTasks, FaSpinner, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { getAllData, getDataWithQuery } from '../firebase/firetstore';
import Loader from '../components/common/Loader'; // Add this import

const DeveloperDashboard = () => {
    const [tasks, setTasks] = useState([]);
    const { user } = useAuth();
    const db = getFirestore();
    const [isLoading, setIsLoading] = useState(true); // Add this line

    useEffect(() => {
        const fetchTasks = async () => {
            setIsLoading(true); // Set loading to true when starting to fetch
            try {
                const collectionPath = 'user_details/' + user.email + '/task_list';
                const fetchedTasks = await getAllData(collectionPath);
                setTasks(fetchedTasks);
            } catch (error) {
                console.error("Error fetching tasks:", error);
            } finally {
                setIsLoading(false); // Set loading to false when fetch is complete
            }
        };

        fetchTasks();
    }, [user.email, db]);

    const getTaskCountByStatus = (status) => {
        return tasks.filter(task => task.status === status).length;
    };

    const TaskCard = ({ icon: Icon, title, count, color }) => (
        <div className={`bg-white p-6 rounded-lg shadow-md border-l-4 ${color}`}>
            <div className="flex items-center mb-4">
                <Icon className={`text-3xl ${color.replace('border-', 'text-')} mr-3`} />
                <h2 className="text-xl font-semibold">{title}</h2>
            </div>
            <p className={`text-4xl font-bold ${color.replace('border-', 'text-')}`}>{count}</p>
        </div>
    );

    return (
        <Layout>
            <div className="flex w-full h-[calc(100vh-4rem)]">
                <SideMenu />
                <div className="flex-1 overflow-hidden">
                    <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
                        <h1 className='font-semibold text-xl text-white'>Dashboard</h1>
                    </div>
                    <div className='p-6 bg-gray-50 h-[calc(100vh-4rem-3.5rem)] overflow-y-auto'>
                        {isLoading ? (
                            <div className="flex justify-center items-center h-full">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                                    <TaskCard icon={FaTasks} title="All Tasks" count={tasks.length} color="border-blue-500" />
                                    <TaskCard icon={FaSpinner} title="In Progress" count={getTaskCountByStatus('inprogress')} color="border-yellow-500" />
                                    <TaskCard icon={FaCheckCircle} title="Completed" count={getTaskCountByStatus('completed')} color="border-green-500" />
                                    <TaskCard icon={FaExclamationCircle} title="Open" count={getTaskCountByStatus('open')} color="border-red-500" />
                                </div>
                                {/* Add your table or other components here */}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default DeveloperDashboard;