import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc, updateDoc, collection, serverTimestamp, getDocs, query, where } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';

const CreateTask = () => {
  const [taskData, setTaskData] = useState({
    description: '',
    status: '',
    estimation: '',
    project_name: '',
    title: '',
    assigned_to: '',
  });
  const [projects, setProjects] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    fetchProjects();
    fetchTaskStatuses();
    // fetchDevelopers() removed from here
  }, []);

  const fetchProjects = async () => {
    try {
      const db = getFirestore();
      const projectsCollection = collection(db, 'projects');
      const activeProjects = [];

      for (const projectName of user.projects) {
        const projectQuery = query(projectsCollection, where('project_name', '==', projectName));
        const projectSnapshot = await getDocs(projectQuery);

        if (!projectSnapshot.empty) {
          const projectData = projectSnapshot.docs[0].data();
          if (projectData.project_status && projectData.project_status.toLowerCase() !== 'in-active') {
            activeProjects.push(projectName);
          }
        }
      }

      setProjects(activeProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
      toast.error('Failed to fetch projects');
    }
  };

  const fetchTaskStatuses = async () => {
    try {
      const configDoc = await getDoc(doc(db, 'configs', 'task_life_cycle'));
      if (configDoc.exists()) {
        setTaskStatuses(configDoc.data().task_life_cycle);
        setTaskData(prevData => ({ ...prevData, status: configDoc.data().task_life_cycle[0] }));
      }
    } catch (error) {
      console.error('Error fetching task statuses:', error);
      toast.error('Failed to fetch task statuses');
    }
  };

  const fetchDevelopers = async (projectName) => {
    try {
      const usersCollection = collection(db, 'user_details');
      const usersQuery = query(usersCollection,
        where('role', 'in', ['developer', 'lead']),
        where('projects', 'array-contains', projectName)
      );
      const usersSnapshot = await getDocs(usersQuery);
      const developersList = usersSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .map(developer => ({
          email: developer.email,
          name: `${developer.first_name} ${developer.last_name} (${developer.email})`,
          isCurrentUser: developer.email === user.email
        }));

      // Add the current user if they're not already in the list
      if (!developersList.some(dev => dev.email === user.email)) {
        developersList.unshift({
          email: user.email,
          name: `${user.first_name} ${user.last_name} (${user.email}) (You)`,
          isCurrentUser: true
        });
      }

      setDevelopers(developersList);

      // Remove the automatic assignment
      // setTaskData(prevData => ({
      //   ...prevData,
      //   assigned_to: user.email
      // }));
    } catch (error) {
      console.error("Error fetching developers:", error);
      toast.error("Failed to fetch developers");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTaskData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'project_name') {
      if (value) {
        fetchDevelopers(value);
      } else {
        setDevelopers([]);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const taskIdRef = doc(db, 'configs', 'task_id');
      const taskIdDoc = await getDoc(taskIdRef);
      let nextTaskId = 1;
      if (taskIdDoc.exists()) {
        nextTaskId = taskIdDoc.data().task_id + 1;
      }

      const prefix = taskData.project_name.substring(0, 3).toUpperCase();
      const formattedTaskId = `${prefix}${nextTaskId.toString().padStart(2, '0')}`;
      const finalTaskId = formattedTaskId.replace(/0(\d\d)$/, '$1');

      let assignedDeveloperFirstName = '';
      let assignedDeveloperLastName = '';
      let assignedTo = '';

      if (user.role === 'developer' || user.role === 'lead') {
        // Assign to the logged-in user (developer or lead)
        assignedTo = user.email;
        assignedDeveloperFirstName = user.first_name;
        assignedDeveloperLastName = user.last_name;
      }

      if (user.role === 'lead' && taskData.assigned_to) {
        // If a lead has selected an assignee (including themselves)
        if (taskData.assigned_to === user.email) {
          // Lead assigned to themselves
          assignedTo = user.email;
          assignedDeveloperFirstName = user.first_name;
          assignedDeveloperLastName = user.last_name;
        } else {
          // Lead assigned to someone else
          const developerDoc = await getDoc(doc(db, 'user_details', taskData.assigned_to));
          if (developerDoc.exists()) {
            const developerData = developerDoc.data();
            assignedTo = taskData.assigned_to;
            assignedDeveloperFirstName = developerData.first_name || '';
            assignedDeveloperLastName = developerData.last_name || '';
          }
        }
      }

      const taskDoc = {
        ...taskData,
        created_at: serverTimestamp(),
        creator: user.role,
        creator_email: user.email,
        owner_first_name: user.first_name,
        owner_last_name: user.last_name,
        id: finalTaskId,
        assigned_to: assignedTo,
        assigned_to_first_name: assignedDeveloperFirstName,
        assigned_to_last_name: assignedDeveloperLastName,
        assigned: Boolean(assignedTo) && assignedTo !== user.email,
      };

      await setDoc(doc(db, 'task_list', finalTaskId), taskDoc);
      await setDoc(doc(db, `user_details/${user.email}/task_list`, finalTaskId), taskDoc);

      if (assignedTo && assignedTo !== user.email) {
        await setDoc(doc(db, `user_details/${assignedTo}/task_list`, finalTaskId), taskDoc);
      }

      await updateDoc(taskIdRef, { task_id: nextTaskId });

      toast.success('Task created successfully!');
      setTaskData({
        description: '',
        status: taskStatuses[0],
        estimation: '',
        project_name: '',
        title: '',
        assigned_to: '',
      });
    } catch (error) {
      console.error("Error creating task:", error);
      toast.error('Failed to create task. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1 overflow-y-auto">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>Create New Task</h1>
          </div>
          <div className='p-6 bg-gray-50'>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="project_name">
                      Project
                    </label>
                    <select
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="project_name"
                      name="project_name"
                      value={taskData.project_name}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select a project</option>
                      {projects.map((project, index) => (
                        <option key={index} value={project}>{capitalizeFirstLetter(project)}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                      Status
                    </label>
                    <select
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="status"
                      name="status"
                      value={taskData.status}
                      onChange={handleChange}
                      required
                    >
                      {taskStatuses.map((status, index) => (
                        <option key={index} value={status}>{status}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                    Title
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="title"
                    type="text"
                    name="title"
                    value={taskData.title}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="description"
                    name="description"
                    value={taskData.description}
                    onChange={handleChange}
                    required
                    rows="4"
                    style={{ minHeight: '100px' }}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="estimation">
                      Estimation (hours)
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="estimation"
                      type="number"
                      name="estimation"
                      value={taskData.estimation}
                      onChange={handleChange}
                      required
                      min="0"
                      step="0.1"
                    />
                  </div>
                  {user.role === 'lead' && (
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="assigned_to">
                        Assign To
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="assigned_to"
                        name="assigned_to"
                        value={taskData.assigned_to}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select a user</option>
                        {developers.map((developer, index) => (
                          <option key={index} value={developer.email}>
                            {developer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  <button
                    className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Creating...' : 'Create Task'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateTask;
