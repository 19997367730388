import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../common/Layout';
import Select from 'react-select';
import { getDataWithQuery, getAllData, deleteTask, fetchTaskLifestyleConfig } from '../../firebase/firetstore';
import { useAuth } from '../../context/AuthContext';
import SideMenu from '../common/SideMenu';
import { FaFilter, FaTimes, FaSort, FaTrash, FaEye, FaBars } from 'react-icons/fa';
import Pagination from '../common/Pagination';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const ManagerTaskList = () => {
    const navigate = useNavigate();
    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '38px',
            borderColor: state.isFocused ? '#f97316' : '#d1d5db',
            boxShadow: state.isFocused ? '0 0 0 1px #f97316' : 'none',
            '&:hover': {
                borderColor: '#f97316',
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#f97316' : state.isFocused ? '#fed7aa' : null,
            color: state.isSelected ? 'white' : 'black',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#4b5563',
        }),
    };
    const { user } = useAuth();
    const { projectName } = useParams();
    const [activeProjectTab, setActiveProjectTab] = useState('');
    const [activeRoleTab, setActiveRoleTab] = useState('lead');
    const [tasks, setTasks] = useState([]);
    const [activeTab, setActiveTab] = useState('project');
    const [isLoading, setIsLoading] = useState(true);
    const [statuses, setStatuses] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc');
    const [developers, setDevelopers] = useState([]);
    const [selectedDevelopers, setSelectedDevelopers] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasksPerPage] = useState(10);  // You can adjust this number as needed
    const [projects, setProjects] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [activeDateFilter, setActiveDateFilter] = useState('today');
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [filterSidebarPosition, setFilterSidebarPosition] = useState('-translate-x-full');
    const [dateError, setDateError] = useState('');
    useEffect(() => {
        if (projectName) {
            setActiveProjectTab(projectName);
        } else if (user && user.projects && user.projects.length > 0) {
            setActiveProjectTab(user.projects[0]);
        }
        fetchFilters();
        fetchUsers();
        handleDateFilterChange('today')
    }, [user, projectName]);


    useEffect(() => {
        if (activeProjectTab) {
            setActiveProjectTab(activeProjectTab);
            fetchTasks();
        }
    }, [activeProjectTab, activeRoleTab]);
    const fetchFilters = async () => {
        try {
            const lifestyles = await fetchTaskLifestyleConfig("configs", "task_life_cycle");
            setStatuses(lifestyles?.task_life_cycle || []);
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    };

    const fetchUsers = async () => {
        try {
            const db = getFirestore();
            const usersCollection = collection(db, 'user_details');
            const usersSnapshot = await getDocs(usersCollection);

            const users = usersSnapshot.docs.map(doc => ({
                email: doc.id,
                ...doc.data()
            })).filter(user => user.role === 'developer' || user.role === 'lead');
            setAllUsers(users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };


    const fetchTasks = async () => {
        setIsLoading(true);
        try {
            const collectionPath = 'task_list';
            let fetchedTasks = [];

            if (activeRoleTab === 'lead') {
                const whereConditions = [
                    { field: 'project_name', operator: '==', value: activeProjectTab },
                    { field: 'creator', operator: '==', value: 'lead' },
                    { field: 'assigned', operator: '==', value: false }
                ];
                fetchedTasks = await getDataWithQuery(collectionPath, whereConditions);
            } else if (activeRoleTab === 'developer') {
                // Fetch tasks created by developers
                const developerCreatedTasks = await getDataWithQuery(collectionPath, [
                    { field: 'project_name', operator: '==', value: activeProjectTab },
                    { field: 'creator', operator: '==', value: 'developer' }
                ]);

                // Fetch tasks assigned to developers
                const assignedTasks = await getDataWithQuery(collectionPath, [
                    { field: 'project_name', operator: '==', value: activeProjectTab },
                    { field: 'assigned', operator: '==', value: true }
                ]);

                // Combine and remove duplicates
                fetchedTasks = [...developerCreatedTasks, ...assignedTasks];
                fetchedTasks = Array.from(new Set(fetchedTasks.map(task => task.id)))
                    .map(id => fetchedTasks.find(task => task.id === id));
            }

            setTasks(fetchedTasks);

            // Extract unique developers
            const uniqueDevelopers = [...new Set(fetchedTasks.map(task => task.creator_email || task.assigned_to))];
            setDevelopers(uniqueDevelopers);

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching tasks:", error);
            setIsLoading(false);
        }
    };

    const getDateRange = (filter) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const weekStart = new Date(today);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);

        switch (filter) {
            case 'yesterday':
                return {
                    start: yesterday,
                    end: new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999)
                };
            case 'today':
                return {
                    start: today,
                    end: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)
                };
            case 'this_week':
                return {
                    start: weekStart,
                    end: weekEnd
                };
            default:
                return null;
        }
    };

    const handleDateFilterChange = (filter) => {
        setActiveDateFilter(filter);
        setDateError(''); // Clear any previous errors

        if (filter === 'all') {
            setFromDate('');
            setToDate('');
            setMinDate('');
            setMaxDate('');
        } else {
            const range = getDateRange(filter);
            if (range) {
                const startDate = new Date(range.start.getTime() - range.start.getTimezoneOffset() * 60000);
                const endDate = new Date(range.end.getTime() - range.end.getTimezoneOffset() * 60000);
                const newFromDate = startDate.toISOString().split('T')[0];
                const newToDate = endDate.toISOString().split('T')[0];

                // Validate dates
                if (newFromDate > newToDate) {
                    setDateError('From date cannot be later than To date');
                    return;
                }

                setFromDate(newFromDate);
                setToDate(newToDate);

                if (filter === 'this_week') {
                    setMinDate(newFromDate);
                    setMaxDate(newToDate);
                } else {
                    setMinDate('');
                    setMaxDate('');
                }
            }
        }
    };

    const handleFromDateChange = (e) => {
        const newFromDate = e.target.value;
        setFromDate(newFromDate);
        setDateError('');

        if (toDate && newFromDate > toDate) {
            setDateError('From date cannot be later than To date');
        }
    };

    const handleToDateChange = (e) => {
        const newToDate = e.target.value;
        setToDate(newToDate);
        setDateError('');

        if (fromDate && newToDate < fromDate) {
            setDateError('To date cannot be earlier than From date');
        }
    };

    const DateFilterTabs = ({ activeDateFilter, handleDateFilterChange, className = "", isOutside = false }) => {
        const baseButtonClass = "px-4 py-2 rounded-full font-medium transition-colors duration-200";
        const activeClass = "bg-orange-500 text-white shadow-md";
        const inactiveClass = "bg-gray-200 text-gray-700 hover:bg-gray-300";

        const getButtonClass = (filter) => {
            return `${baseButtonClass} ${activeDateFilter === filter ? activeClass : inactiveClass} ${isOutside ? 'text-sm md:text-base' : 'text-xs'}`;
        };

        return (
            <div className={`flex flex-wrap gap-2 ${className} ${isOutside ? 'p-3 rounded-lg' : 'bg-gray-50 p-2 rounded-md'}`}>
                <button
                    className={getButtonClass('today')}
                    onClick={() => handleDateFilterChange('today')}
                >
                    Today
                </button>
                <button
                    className={getButtonClass('yesterday')}
                    onClick={() => handleDateFilterChange('yesterday')}
                >
                    Yesterday
                </button>
                <button
                    className={getButtonClass('this_week')}
                    onClick={() => handleDateFilterChange('this_week')}
                >
                    This Week
                </button>
                <button
                    className={getButtonClass('all')}
                    onClick={() => handleDateFilterChange('all')}
                >
                    All
                </button>
            </div>
        );
    };

    const handleDateFilter = () => {
        return tasks.filter(task => {
            const createdDate = new Date(task.created_at.seconds * 1000);
            const updatedDate = task.updated_at ? new Date(task.updated_at.seconds * 1000) : createdDate;
            const latestDate = new Date(Math.max(createdDate, updatedDate));
            latestDate.setHours(0, 0, 0, 0);

            let from = fromDate ? new Date(fromDate) : new Date(0);
            from.setHours(0, 0, 0, 0);
            let to = toDate ? new Date(toDate) : new Date();
            to.setHours(23, 59, 59, 999);
            return latestDate >= from && latestDate <= to;
        });
    };

    const handleSortClick = () => {
        const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        setSortOrder(newSortOrder);
    };

    const handleSortByCreatedAt = () => {
        setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };
    const handleProjectTabChange = (project) => {
        setActiveProjectTab(project);
        setSelectedStatuses([]);
        setSelectedDevelopers([]);
        const filteredUsers = filterUsersByProject(project);
        setDevelopers(filteredUsers.map(user => user.email));
    };

    const filterUsersByProject = (projectName) => {
        return allUsers.filter(user =>
            user.projects && user.projects.includes(projectName)
        );
    };


    const handleRoleTabChange = (role) => {
        setActiveRoleTab(role);
    };

    const filteredTasks = handleDateFilter()
        .filter(task =>
            (selectedStatuses.length === 0 || selectedStatuses.includes(task.status)) &&
            (selectedDevelopers.length === 0 || selectedDevelopers.includes(task.assigned_to))
        )
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.created_at.seconds * 1000) - new Date(b.created_at.seconds * 1000);
            } else {
                return new Date(b.created_at.seconds * 1000) - new Date(a.created_at.seconds * 1000);
            }
        });

    // Get current tasks
    const indexOfLastTask = currentPage * tasksPerPage;
    const indexOfFirstTask = indexOfLastTask - tasksPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);

    // Change page
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleTaskClick = (taskId) => {
        navigate(`/task/${taskId}`);
    };


    const clearAllFilters = () => {
        setSelectedStatuses([]);
        setSelectedDevelopers([]);
        setFromDate('');
        setToDate('');
        setActiveDateFilter('today');
        handleDateFilterChange('today');
        setCurrentPage(1);

        // Reset project selection if needed
        if (user && user.projects && user.projects.length > 0) {
            setActiveProjectTab(user.projects[0]);
        } else {
            setActiveProjectTab('');
        }

        // Reset role selection
        setActiveRoleTab('lead');

        // Reset sort order
        setSortOrder('desc');

        // Fetch tasks again with cleared filters
        fetchTasks();
    };

    useEffect(() => {
        setFilterSidebarPosition(isFilterSidebarOpen ? 'translate-x-0' : '-translate-x-full');
    }, [isFilterSidebarOpen]);

    const renderProjectAndRoleSelection = () => (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                <div className="w-full md:w-64">
                    <Select
                        options={user.projects.map(project => ({
                            value: project,
                            label: capitalizeFirstLetter(project)
                        }))}
                        value={activeProjectTab ? {
                            value: activeProjectTab,
                            label: capitalizeFirstLetter(activeProjectTab)
                        } : null}
                        onChange={(selectedOption) => handleProjectTabChange(selectedOption.value)}
                        placeholder="Select a project"
                        styles={customSelectStyles}
                        className="text-sm"
                    />
                </div>
                <div className="flex space-x-4">
                    {['lead', 'developer'].map(role => (
                        <button
                            key={role}
                            className={`px-4 py-2 rounded-full font-medium text-sm transition duration-200 ease-in-out ${activeRoleTab === role
                                ? 'bg-orange-500 text-white'
                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                }`}
                            onClick={() => handleRoleTabChange(role)}
                        >
                            {role.charAt(0).toUpperCase() + role.slice(1)} Tasks
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );

    const renderFilteredTable = () => (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
                </div>
            ) : filteredTasks.length === 0 ? (
                <div className="flex flex-col justify-center items-center mt-8">
                    <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
                    </svg>
                    <p className="text-xl text-gray-500 font-semibold">No Tasks Found</p>
                    <p className="text-sm text-gray-400 mt-2">There are no tasks available at the moment.</p>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gradient-to-r from-gray-800 to-gray-900">
                            <tr>
                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">ID</th>
                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider w-1/3">Title</th>
                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">Status</th>
                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">Estimation (hours)</th>
                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">Task Owner</th>
                                <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider">
                                    Created At
                                    <button onClick={handleSortByCreatedAt} className="ml-2 focus:outline-none">
                                        <FaSort className="inline text-white" />
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {currentTasks.map((task) => (
                                <tr key={task.id} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        <Link to={`/task/${task.id}`} className="text-blue-600 hover:text-blue-800 hover:underline">
                                            {task.id}
                                        </Link>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="text-sm font-semibold text-gray-900 line-clamp-2 break-words" title={task.title}>
                                            <Link
                                                to={`/task/${task.id}`}
                                                className="text-blue-600 hover:text-blue-800 hover:underline"
                                            >
                                                {task.title}
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${task.status === 'completed' ? 'bg-green-100 text-green-800' :
                                            task.status === 'in progress' ? 'bg-yellow-100 text-yellow-800' :
                                                'bg-red-100 text-red-800'
                                            }`}>
                                            {task.status}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{task.estimation}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {task.assigned_to_first_name && task.assigned_to_last_name
                                            ? `${task.assigned_to_first_name} ${task.assigned_to_last_name.charAt(0)}`
                                            : task.assigned_to}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {new Date(task.created_at.seconds * 1000).toLocaleDateString()}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )
            }
        </div >
    );

    return (
        <Layout>
            <div className="flex w-full h-[calc(100vh-4rem)]">
                <SideMenu />
                <div className="flex-1 overflow-hidden relative">
                    <div className="h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600">
                        <h1 className="text-2xl font-bold text-white">Manager Task List</h1>
                    </div>
                    <div className="p-6 bg-gray-50 h-[calc(100vh-4rem-3.5rem)] overflow-y-auto">
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center">
                                <button
                                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                                    className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200 mr-4"
                                >
                                    <FaBars className="text-orange-500" />
                                </button>
                                <DateFilterTabs
                                    activeDateFilter={activeDateFilter}
                                    handleDateFilterChange={handleDateFilterChange}
                                    isOutside={true}
                                />
                            </div>
                        </div>
                        {renderProjectAndRoleSelection()}
                        {renderFilteredTable()}
                        {!isLoading && filteredTasks.length > 0 && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={Math.ceil(filteredTasks.length / tasksPerPage)}
                                onPageChange={handlePageChange}
                            />
                        )}
                    </div>
                    {isFilterSidebarOpen && (
                        <div className={`absolute top-14 left-0 w-80 h-[calc(100vh-4rem-3.5rem)] bg-white shadow-lg overflow-y-auto p-6 transition-all duration-300 ease-in-out transform ${filterSidebarPosition}`}>
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-lg font-semibold">Filters</h2>
                                <button
                                    onClick={() => setIsFilterSidebarOpen(false)}
                                    className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
                                >
                                    <FaTimes className="text-gray-500 hover:text-gray-700" />
                                </button>
                            </div>
                            <div className="space-y-4">
                                <div>
                                    <h3 className="font-medium mb-2">Date Range</h3>
                                    <DateFilterTabs
                                        activeDateFilter={activeDateFilter}
                                        handleDateFilterChange={handleDateFilterChange}
                                        className="flex-wrap"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">From</label>
                                    <input
                                        type="date"
                                        value={fromDate}
                                        onChange={handleFromDateChange}
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent date-input-grey"
                                        disabled={activeDateFilter === 'yesterday' || activeDateFilter === 'today'}
                                        min={minDate}
                                        max={maxDate}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">To</label>
                                    <input
                                        type="date"
                                        value={toDate}
                                        onChange={handleToDateChange}
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent date-input-grey"
                                        disabled={activeDateFilter === 'yesterday' || activeDateFilter === 'today'}
                                        min={minDate}
                                        max={maxDate}
                                    />
                                </div>
                                {dateError && (
                                    <div className="text-red-500 text-sm mt-2">{dateError}</div>
                                )}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Projects</label>
                                    <Select
                                        isMulti
                                        options={statuses.map(status => ({ value: status, label: status }))}
                                        value={selectedStatuses.map(status => ({ value: status, label: status }))}
                                        onChange={(selectedOptions) => setSelectedStatuses(selectedOptions.map(option => option.value))}
                                        placeholder="Filter by Status"
                                        className="w-full md:w-64"
                                        styles={customSelectStyles}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Users</label>
                                    <Select
                                        isMulti
                                        options={filterUsersByProject(activeProjectTab).map(user => ({
                                            value: user.email,
                                            label: `${user.first_name} ${user.last_name} (${user.role})`
                                        }))}
                                        value={selectedDevelopers.map(email => {
                                            const user = filterUsersByProject(activeProjectTab).find(u => u.email === email);
                                            return {
                                                value: email,
                                                label: user ? `${user.first_name} ${user.last_name} (${user.role})` : email
                                            };
                                        })}
                                        onChange={(selectedOptions) => setSelectedDevelopers(selectedOptions.map(option => option.value))}
                                        placeholder="Filter by User"
                                        className="w-full md:w-64"
                                        styles={customSelectStyles}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                    />
                                </div>
                                <button
                                    onClick={clearAllFilters}
                                    className="w-25 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                                >
                                    Clear All Filters
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout >
    );
};

export default ManagerTaskList;

