import React, { useState, useEffect } from 'react';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { useAuth } from '../context/AuthContext';
import { FaTasks, FaUserCog, FaProjectDiagram, FaExclamationTriangle, FaCircle } from 'react-icons/fa';
import { getDataWithQuery, getAllData } from '../firebase/firetstore';
import { getFirestore, collection, query, where, getDocs, limit } from 'firebase/firestore';
import Loader from '../components/common/Loader';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const LeadDashboard = () => {
  const [myTasksCount, setMyTasksCount] = useState(0);
  const [developerTasksCount, setDeveloperTasksCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [projectChartData, setProjectChartData] = useState({});
  const [projectStatuses, setProjectStatuses] = useState({});
  const { user } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const collectionPath = 'task_list';

        // Fetch tasks created by developers
        const developerCreatedTasks = await getDataWithQuery(collectionPath, [
          { field: 'creator', operator: '==', value: 'developer' },
          { field: 'project_name', operator: 'in', value: user.projects }
        ]);
        // Fetch tasks assigned to developers
        const assignedTasks = await getDataWithQuery(collectionPath, [
          { field: 'assigned', operator: '==', value: true },
          { field: 'project_name', operator: 'in', value: user.projects }
        ]);

        // Combine and remove duplicatesss
        const allDeveloperTasks = [...developerCreatedTasks, ...assignedTasks];
        const uniqueTasks = Array.from(new Set(allDeveloperTasks.map(task => task.id)))
          .map(id => allDeveloperTasks.find(task => task.id === id));
        setDeveloperTasksCount(uniqueTasks.length);

        // Fetch lead tasks
        const leadTasksCreatedConditions = [
          { field: 'creator', operator: '==', value: 'lead' },
          { field: 'creator_email', operator: '==', value: user.email },
          { field: "assigned", operator: "==", value: false },
        ];

        const leadTasksAssignedConditions = [
          { field: 'creator', operator: '==', value: 'lead' },
          { field: 'assigned_to', operator: '==', value: user.email }
        ];

        const leadTasksCreated = await getDataWithQuery(collectionPath, leadTasksCreatedConditions);
        const leadTasksAssigned = await getDataWithQuery(collectionPath, leadTasksAssignedConditions);

        // Combine the results and remove duplicates
        const leadTasks = [...leadTasksCreated, ...leadTasksAssigned];
        const uniqueLeadTasks = Array.from(new Set(leadTasks.map(task => task.id)))
          .map(id => leadTasks.find(task => task.id === id));

        setMyTasksCount(uniqueLeadTasks.length);

        // Set projects count
        setProjectsCount(user.projects.length);

        // Fetch project statuses from the projects collection
        const projectStatusesData = {};
        for (const projectName of user.projects) {
          try {
            const projectsCollection = collection(db, 'projects');
            const projectQuery = query(projectsCollection, where('project_name', '==', projectName), limit(1));
            const projectSnapshot = await getDocs(projectQuery);

            if (!projectSnapshot.empty) {
              const projectDoc = projectSnapshot.docs[0];
              projectStatusesData[projectName] = projectDoc.data().project_status || 'unknown';
            } else {
              projectStatusesData[projectName] = 'unknown';
            }
          } catch (error) {
            console.error(`Error fetching status for project ${projectName}:`, error);
            projectStatusesData[projectName] = 'unknown';
          }
        }
        setProjectStatuses(projectStatusesData);

        // Prepare chart data
        const projectTaskStatusData = {};
        for (const projectName of user.projects) {
          const projectTasks = await getDataWithQuery(collectionPath, [
            { field: 'project_name', operator: '==', value: projectName },
          ]);
          const taskStatusCounts = { open: 0, inprogress: 0, completed: 0 };
          projectTasks.forEach(task => {
            const status = task.status.toLowerCase();
            if (status in taskStatusCounts) {
              taskStatusCounts[status]++;
            }
          });
          projectTaskStatusData[projectName] = taskStatusCounts;
        }
        prepareProjectChartData(projectTaskStatusData);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user.email, user.projects, db]);

  const prepareProjectChartData = (projectTaskStatusData) => {
    const projectCharts = {};

    for (const [projectName, statusCounts] of Object.entries(projectTaskStatusData)) {
      projectCharts[projectName] = {
        labels: ['Open', 'In Progress', 'Completed'],
        datasets: [
          {
            data: [statusCounts.open, statusCounts.inprogress, statusCounts.completed],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
          }
        ]
      };
    }

    setProjectChartData(projectCharts);
  };


  const getStatusText = (status) => {
    return status.charAt(0).toUpperCase() + status.slice(1).replace('-', ' ');
  };
  const Card = ({ title, icon: Icon, count }) => (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        <Icon className="text-3xl text-orange-500 mr-3" />
        <h2 className="text-xl font-semibold">{title}</h2>
      </div>
      <p className="text-4xl font-bold text-gray-700">{count}</p>
    </div>
  );

  return (
    <Layout>
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1 overflow-y-auto">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>Dashboard</h1>
          </div>
          <div className='p-6 bg-gray-50'>
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                  <Card title="My Tasks" icon={FaTasks} count={myTasksCount} />
                  <Card title="Other Tasks" icon={FaUserCog} count={developerTasksCount} />
                  <Card title="Projects" icon={FaProjectDiagram} count={projectsCount} />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                  {Object.entries(projectChartData).map(([projectName, chartData]) => (
                    <div key={projectName} className="bg-white p-6 rounded-lg shadow-md">
                      <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-semibold text-gray-800">
                          {projectName.charAt(0).toUpperCase() + projectName.slice(1).toLowerCase()}
                        </h2>
                        <div className={`flex items-center px-2 py-1 rounded-full border-2 ${projectStatuses[projectName] === 'active'
                          ? 'bg-green-100 text-green-800 border-green-300'
                          : projectStatuses[projectName] === 'in-active'
                            ? 'bg-red-100 text-red-800 border-red-300'
                            : 'bg-gray-100 text-gray-800 border-gray-300'
                          }`}>
                          <span className="text-xs font-medium">
                            {getStatusText(projectStatuses[projectName])}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        {chartData.datasets[0].data.some(value => value > 0) ? (
                          <>
                            <div style={{ width: '100%', height: '250px', maxWidth: '400px' }}>
                              <Pie
                                data={chartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  plugins: {
                                    legend: {
                                      display: false
                                    },
                                    tooltip: {
                                      callbacks: {
                                        title: () => '',
                                        label: (context) => {
                                          const label = context.label || '';
                                          const value = context.parsed || 0;
                                          return `${label}: ${value}`;
                                        }
                                      }
                                    }
                                  },
                                }}
                              />
                            </div>
                            <div className="mt-4 grid grid-cols-3 gap-4 text-center w-full">
                              {chartData.labels.map((label, index) => (
                                <div key={label} className="flex flex-col items-center">
                                  <div className="w-4 h-4 rounded-full mb-1" style={{ backgroundColor: chartData.datasets[0].backgroundColor[index] }}></div>
                                  <p className="text-sm font-medium">{label}</p>
                                  <p className="text-lg font-bold">{chartData.datasets[0].data[index]}</p>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center h-64">
                            <FaExclamationTriangle className="text-5xl text-yellow-500 mb-4" />
                            <p className="text-xl text-gray-600 font-semibold">No tasks found for this project</p>
                            <p className="text-sm text-gray-500 mt-2">Create some tasks to see the status chart</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LeadDashboard;