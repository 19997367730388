import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, limit } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { useAuth } from '../context/AuthContext';
import { FaProjectDiagram, FaTasks, FaUsers, FaArrowRight, FaExclamationTriangle, FaCircle } from 'react-icons/fa';
import Loader from '../components/common/Loader';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const ManagerDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [linkedUsers, setLinkedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [projectChartData, setProjectChartData] = useState({});
  const [projectTaskStatus, setProjectTaskStatus] = useState({});
  const [projectStatuses, setProjectStatuses] = useState({});
  const { user } = useAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (user && user.email) {
        try {
          // Fetch projects associated with the manager
          const usersCollection = collection(db, 'user_details');
          const userQuery = query(usersCollection, where('manager_email', '==', user.email));
          const userSnapshot = await getDocs(userQuery);
          const projectsList = [];
          // userSnapshot.forEach(doc => {
          // const userData = doc.data();
          if (user.projects) {
            projectsList.push(...user.projects);
          }
          // });
          setProjects([...new Set(projectsList)]);
          // Fetch tasks for all projects
          const tasksCollection = collection(db, 'task_list');
          const taskQuery = query(tasksCollection, where('project_name', 'in', projectsList));
          const taskSnapshot = await getDocs(taskQuery);
          const taskList = taskSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setTasks(taskList);

          // Set linked users
          const linkedUsersList = userSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setLinkedUsers(linkedUsersList);

          // Fetch task statuses for each project
          const projectTaskStatusData = {};
          for (const projectName of projectsList) {
            const projectTaskQuery = query(tasksCollection, where('project_name', '==', projectName));
            const projectTaskSnapshot = await getDocs(projectTaskQuery);
            const taskStatusCounts = {
              open: 0,
              inprogress: 0,
              completed: 0
            };
            projectTaskSnapshot.forEach(doc => {
              const taskStatus = doc.data().status;
              if (taskStatus in taskStatusCounts) {
                taskStatusCounts[taskStatus]++;
              }
            });
            projectTaskStatusData[projectName] = taskStatusCounts;
          }
          setProjectTaskStatus(projectTaskStatusData);

          // Fetch project statuses
          const projectStatusesData = {};
          for (const projectName of projectsList) {
            try {
              const projectsCollection = collection(db, 'projects');
              const projectQuery = query(projectsCollection, where('project_name', '==', projectName), limit(1));
              const projectSnapshot = await getDocs(projectQuery);

              if (!projectSnapshot.empty) {
                const projectDoc = projectSnapshot.docs[0];
                projectStatusesData[projectName] = projectDoc.data().project_status || 'unknown';
              } else {
                console.log(`No project document found for project name: ${projectName}`);
                projectStatusesData[projectName] = 'unknown';
              }
            } catch (error) {
              console.error(`Error fetching status for project ${projectName}:`, error);
              projectStatusesData[projectName] = 'unknown';
            }
          }
          setProjectStatuses(projectStatusesData);

          // Prepare chart data
          prepareProjectChartData(projectTaskStatusData);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [user, db]);

  const prepareProjectChartData = (projectTaskStatusData) => {
    const projectCharts = {};

    for (const [projectName, statusCounts] of Object.entries(projectTaskStatusData)) {
      projectCharts[projectName] = {
        labels: ['Open', 'In Progress', 'Completed'],
        datasets: [
          {
            data: [statusCounts.open, statusCounts.inprogress, statusCounts.completed],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
          }
        ]
      };
    }

    setProjectChartData(projectCharts);
  };

  const handleProjectClick = (projectName) => {
    navigate(`/task-list/${encodeURIComponent(projectName)}`);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed': return 'text-green-500';
      case 'in-progress': return 'text-yellow-500';
      case 'open': return 'text-pink-500';
      case 'not-started': return 'text-red-500';
      default: return 'text-gray-500';
    }
  };

  const getStatusText = (status) => {
    return status.charAt(0).toUpperCase() + status.slice(1).replace('-', ' ');
  };

  return (
    <Layout>
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1 overflow-y-auto">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>Dashboard</h1>
          </div>
          <div className='p-6 bg-gray-50'>
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                  <div className="bg-white p-6 rounded-lg shadow-md">
                    <div className="flex items-center mb-4">
                      <FaProjectDiagram className="text-3xl text-orange-500 mr-3" />
                      <h2 className="text-xl font-semibold">Active Projects</h2>
                    </div>
                    <p className="text-4xl font-bold text-orange-500">{projects.length}</p>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-md">
                    <div className="flex items-center mb-4">
                      <FaTasks className="text-3xl text-orange-500 mr-3" />
                      <h2 className="text-xl font-semibold">Total Tasks</h2>
                    </div>
                    <p className="text-4xl font-bold text-orange-500">{tasks.length}</p>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-md">
                    <div className="flex items-center mb-4">
                      <FaUsers className="text-3xl text-orange-500 mr-3" />
                      <h2 className="text-xl font-semibold">Team Members</h2>
                    </div>
                    <p className="text-4xl font-bold text-orange-500">{linkedUsers.length}</p>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                  {Object.entries(projectChartData).map(([projectName, chartData]) => (
                    <div key={projectName} className="bg-white p-6 rounded-lg shadow-md">
                      <div className="flex items-center justify-between mb-4">
                        <h2
                          key={projectName}
                          className="text-xl font-semibold text-blue-500 hover:underline cursor-pointer"
                          onClick={() => handleProjectClick(projectName)}
                        >
                          {projectName.charAt(0).toUpperCase() + projectName.slice(1).toLowerCase()}
                        </h2>
                        <div className={`flex items-center px-2 py-1 rounded-full border-2 ${projectStatuses[projectName] === 'active'
                          ? 'bg-green-100 text-green-800 border-green-300'
                          : projectStatuses[projectName] === 'in-active'
                            ? 'bg-red-100 text-red-800 border-red-300'
                            : 'bg-gray-100 text-gray-800 border-gray-300'
                          }`}>
                          <span className="text-xs font-medium">
                            {getStatusText(projectStatuses[projectName])}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        {chartData.datasets[0].data.some(value => value > 0) ? (
                          <>
                            <div style={{ width: '100%', height: '250px', maxWidth: '400px' }}>
                              <Pie
                                data={chartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  plugins: {
                                    legend: {
                                      display: false
                                    },
                                    tooltip: {
                                      callbacks: {
                                        title: () => '',
                                        label: (context) => {
                                          const label = context.label || '';
                                          const value = context.parsed || 0;
                                          return `${label}: ${value}`;
                                        }
                                      }
                                    }
                                  },
                                }}
                              />
                            </div>
                            <div className="mt-4 grid grid-cols-3 gap-4 text-center w-full">
                              {chartData.labels.map((label, index) => (
                                <div key={label} className="flex flex-col items-center">
                                  <div className="w-4 h-4 rounded-full mb-1" style={{ backgroundColor: chartData.datasets[0].backgroundColor[index] }}></div>
                                  <p className="text-sm font-medium">{label}</p>
                                  <p className="text-lg font-bold">{chartData.datasets[0].data[index]}</p>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center h-64">
                            <FaExclamationTriangle className="text-5xl text-yellow-500 mb-4" />
                            <p className="text-xl text-gray-600 font-semibold">No tasks found for this project</p>
                            <p className="text-sm text-gray-500 mt-2">Create some tasks to see the status chart</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="bg-white p-6 rounded-lg shadow-md">
                  <h2 className="text-xl font-semibold mb-4">Projects</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {projects.map((projectName) => (
                      <div
                        key={projectName}
                        className="bg-gray-100 p-4 rounded-lg cursor-pointer hover:bg-gray-200 transition-colors duration-200"
                        onClick={() => handleProjectClick(projectName)}
                      >
                        <div className="flex items-center justify-between">
                          <h3 className="text-lg font-semibold">{projectName}</h3>
                          <FaArrowRight className="text-gray-600" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManagerDashboard;