import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import AdminDashboard from '../../pages/AdminDashboard';
import DeveloperDashboard from '../../pages/DeveloperDashboard';
import LeadDashboard from '../../pages/LeadDashboard';
import ManagerDashboard from '../../pages/ManagerDashboard';
import Login from '../../pages/Login';
import TaskList from '../developer/TaskList';
import TaskDetails from '../developer/TaskDetails';
import LeadTaskDetails from '../lead/LeadTaskDetails';
import LeadTaskList from '../lead/LeadTaskList';
import ManagerTaskList from '../manager/ManagerTaskList';
import ManagerTaskDetails from '../manager/ManagerTaskDetails';
import UserDetails from '../../pages/UserDetails';
import CreateUser from '../../pages/CreateUser';
import CreateTask from '../../pages/CreateTask';
import ProfilePage from '../../pages/ProfilePage';
import TeamPage from '../../pages/TeamPage';
import CreateProjects from '../admin/CreateProjects';
import ImportUser from '../../pages/ImportUser';
import Loader from '../common/Loader';
import NotificationsPage from '../../pages/NotificationsPage';
import AdminProjectManagement from '../admin/AdminProjectManagement';

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
    const { user, loading } = useAuth();

    if (loading) return (
        <div className="flex justify-center items-center h-screen w-screen fixed top-0 left-0 bg-white">
            <Loader />
        </div>
    );
    if (!user) return <Navigate to="/" />;
    if (allowedRoles.length > 0 && !allowedRoles.includes(user.role)) {
        return <Navigate to="/dashboard" />;
    }
    return children;
};

const DashboardRouter = () => {
    const { user } = useAuth();
    switch (user.role) {
        case 'admin': return <AdminDashboard />;
        case 'developer': return <DeveloperDashboard />;
        case 'lead': return <LeadDashboard />;
        case 'manager': return <ManagerDashboard />;
        //default: return <Navigate to="/" />;
    }
};

const ProjectRouter = () => {
    const { user } = useAuth();
    switch (user.role) {
        case 'admin': return <AdminProjectManagement />;
        case 'manager': return <CreateProjects />;
        //default: return <Navigate to="/" />;
    }
}


const TaskListRouter = () => {
    const { user } = useAuth();

    switch (user.role) {
        case 'developer': return <TaskList />;
        case 'lead': return <LeadTaskList />;
        case 'manager': return <ManagerTaskList />;
        default: return <Navigate to="/" />;
    }
};

const TaskDetailsRouter = () => {
    const { user } = useAuth();

    switch (user.role) {
        case 'developer': return <TaskDetails />;
        case 'lead': return <LeadTaskDetails />;
        case 'manager': return <ManagerTaskDetails />;
        default: return <Navigate to="/" />;
    }
};



const CodemRoutes = () => {
    const { user } = useAuth();

    return (
        <Routes>
            <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Login />} />
            <Route path="/dashboard" element={<ProtectedRoute><DashboardRouter /></ProtectedRoute>} />
            <Route path="/admin-dashboard" element={<ProtectedRoute allowedRoles={['admin']}><AdminDashboard /></ProtectedRoute>} />
            <Route path="/user/:userId" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><UserDetails /></ProtectedRoute>} />
            <Route path="/create-user" element={<ProtectedRoute allowedRoles={['admin']}><CreateUser /></ProtectedRoute>} />
            <Route path="/import-users" element={<ProtectedRoute allowedRoles={['admin']}><ImportUser /></ProtectedRoute>} />
            <Route path="/create-task" element={<ProtectedRoute allowedRoles={['developer', 'lead']}><CreateTask /></ProtectedRoute>} />
            <Route path="/team" element={<ProtectedRoute allowedRoles={['manager']}><TeamPage /></ProtectedRoute>} />
            <Route path="/task-list/:projectName?" element={<ProtectedRoute allowedRoles={['developer', 'lead', 'manager']}><TaskListRouter /></ProtectedRoute>} />
            <Route path="/task/:taskId" element={<ProtectedRoute allowedRoles={['developer', 'lead', "manager"]}><TaskDetailsRouter /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute allowedRoles={['developer', 'lead', 'manager']}><ProfilePage /></ProtectedRoute>} />
            <Route path="/create-projects" element={<ProtectedRoute allowedRoles={['manager', 'admin']}><ProjectRouter /></ProtectedRoute>} />
            <Route path="/notifications" element={<ProtectedRoute allowedRoles={['manager', 'admin','lead','developer']}><NotificationsPage /></ProtectedRoute>} />
        </Routes>
    );
};

export default CodemRoutes