import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import api from '../api';
import Loader from '../components/common/Loader';
import Select from 'react-select';
import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import { FaUser } from 'react-icons/fa';

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isManagerView, setIsManagerView] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    fetchUserDetails();
    fetchUserRoles();
    fetchProjects();
    fetchManagers();

    // Check if the user is a manager and came from the team listing page
    if (user.role === 'manager' && location.state?.fromTeamListing) {
      setIsManagerView(true);
      setIsEditing(true);
    }
  }, [userId, user.role, location]);

  const fetchUserDetails = async () => {
    try {
      const response = await api.get(`api/users/${userId}`);
      setUserDetails(response.data);
      await fetchProfileImage(response.data.email);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching user details:', error);
      toast.error(error.response?.data?.error || 'Failed to fetch user details');
    }
  };

  const fetchProfileImage = async (email) => {
    try {
      const response = await api.get(`api/users/${email}/profile-image`);
      if (response.data.success) {
        setProfileImageUrl(response.data.imageUrl);
      }
    } catch (error) {
      console.error('Error fetching profile image:', error);
      // If there's no image, we'll just use the default FaUser icon
    }
  };

  const fetchUserRoles = async () => {
    try {
      const db = getFirestore();
      const rolesDoc = await getDoc(doc(db, 'configs', 'user_roles'));
      if (rolesDoc.exists()) {
        setUserRoles(rolesDoc.data().user_roles);
      }
    } catch (error) {
      console.error('Error fetching user roles:', error);
      toast.error('Failed to fetch user roles');
    }
  };

  const fetchProjects = async () => {
    try {
      const db = getFirestore();
      const projectsCollection = collection(db, '/projects');
      const projectsSnapshot = await getDocs(projectsCollection);
      const projectsList = projectsSnapshot.docs
        .filter(doc => {
          const projectStatus = doc.data().project_status;
          return !projectStatus || projectStatus.toLowerCase() !== 'in-active';
        })
        .map(doc => ({
          value: doc.data().project_name,
          label: doc.data().project_name,
          ...doc.data()
        }));
      setProjects(projectsList);
    } catch (error) {
      console.error('Error fetching active projects:', error);
      toast.error('Failed to fetch active projects');
    }
  };


  const fetchManagers = async () => {
    try {
      const db = getFirestore();
      const usersCollection = collection(db, 'user_details');
      const managersQuery = query(usersCollection, where('role', '==', 'manager'));
      const managersSnapshot = await getDocs(managersQuery);
      const managersList = managersSnapshot.docs.map(doc => ({
        value: doc.data().email,
        label: `${doc.data().first_name} ${doc.data().last_name} (${doc.data().email})`,
        ...doc.data()
      }));
      setManagers(managersList);
    } catch (error) {
      console.error('Error fetching managers:', error);
      toast.error('Failed to fetch managers');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleProjectsChange = (selectedOptions) => {
    const selectedProjects = selectedOptions.map(option => option.value);
    setUserDetails({ ...userDetails, projects: selectedProjects });
  };

  const handleManagerChange = (selectedOption) => {
    setUserDetails({ ...userDetails, manager_email: selectedOption ? selectedOption.value : '' });
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const updatedUserDetails = { ...userDetails };
      if (updatedUserDetails.role === 'manager') {
        updatedUserDetails.manager_email = '';
      }
      await api.put(`api/users/${userId}`, updatedUserDetails);
      setIsEditing(false);
      toast.success('User details updated successfully');

      if (isManagerView) {
        navigate('/team'); // Redirect to team listing page for managers
      } else {
        fetchUserDetails();
      }
    } catch (error) {
      console.error('Error updating user details:', error);
      toast.error(error.response?.data?.error || 'Failed to update user details');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLoading(true);
      await api.delete(`api/users/${userId}`);
      toast.success('User deleted successfully');
      setIsLoading(false);
      navigate('/dashboard');
    } catch (error) {
      setIsLoading(false);
      console.error('Error deleting user:', error);
      toast.error(error.response?.data?.error || 'Failed to delete user');
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const handleCancel = () => {
    if (isManagerView) {
      navigate('/team'); // Redirect to team listing page for managers
    } else {
      setIsEditing(false);
      fetchUserDetails();
    }
    setIsLoading(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setIsLoading(false);
  };

  // const handleImageUpload = async (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.size <= 2 * 1024 * 1024) { // 2MB limit
  //     setIsLoading(true);
  //     const formData = new FormData();
  //     formData.append('profile_image', file);
  //     formData.append('email', userDetails.email);

  //     try {
  //       const response = await api.post('api/users/upload-profile-image', formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       });
  //       console.log('Image upload response:', response.data);
  //       toast.success('Image uploaded successfully');
  //       await fetchProfileImage(userDetails.email);
  //     } catch (error) {
  //       console.error('Error uploading image:', error);
  //       toast.error('Failed to upload image');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   } else {
  //     toast.error('Image size should be less than 2MB');
  //   }
  // };

  if (!userDetails) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1 flex flex-col">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>User Details</h1>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Loader />
            </div>
          ) : (
            <div className='p-6 bg-gray-50 flex-1 overflow-y-auto'>
              <div className="bg-white p-6 rounded-lg shadow flex">
                <div className="w-3/5 pr-4">
                  <form>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        value={userDetails.email}
                        disabled
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
                        First Name
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="first_name"
                        name="first_name"
                        type="text"
                        value={userDetails.first_name}
                        onChange={handleInputChange}
                        disabled={!isEditing || user.role !== 'admin'}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">
                        Last Name
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="last_name"
                        name="last_name"
                        type="text"
                        value={userDetails.last_name}
                        onChange={handleInputChange}
                        disabled={!isEditing || user.role !== 'admin'}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                        Role
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="role"
                        name="role"
                        value={userDetails.role}
                        onChange={handleInputChange}
                        disabled={!isEditing || user.role !== 'admin'}
                      >
                        <option value="">Select a role</option>
                        {userRoles.map((role) => (
                          <option key={role} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>
                    {userDetails.role !== 'manager' && (
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="manager_email">
                          Manager
                        </label>
                        <Select
                          options={managers}
                          onChange={handleManagerChange}
                          value={managers.find(manager => manager.value === userDetails.manager_email)}
                          placeholder="Select a manager"
                          isClearable
                          isSearchable
                          isDisabled={!isEditing || (user.role !== 'admin' && user.role !== 'manager')}
                          className="basic-single"
                          classNamePrefix="select"
                        />
                      </div>
                    )}
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="projects">
                        Projects
                      </label>
                      <Select
                        isMulti
                        name="projects"
                        options={projects}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={projects.filter(project => userDetails.projects.includes(project.label))}
                        onChange={handleProjectsChange}
                        isDisabled={!isEditing || (user.role !== 'admin' && !(user.role === 'manager' && user.email === userDetails.manager_email))}
                      />
                    </div>
                    {isEditing ? (
                      <div className="flex items-center justify-between">
                        <button
                          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          type="button"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                        <button
                          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          type="button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between">
                        {!isManagerView && (
                          <button
                            className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => setIsEditing(true)}
                          >
                            Edit
                          </button>
                        )}
                        {user.role === 'admin' && (
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleDeleteClick}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    )}
                  </form>
                </div>
                <div className="w-2/5 pl-6 border-l border-gray-200">
                  <h2 className="text-lg font-semibold mb-4">User Image</h2>
                  <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center">
                    <label htmlFor="user-image-upload" className="cursor-pointer">
                      {profileImageUrl ? (
                        <img src={profileImageUrl} alt="User" className="w-32 h-32 rounded-full object-cover" />
                      ) : (
                        <FaUser className="w-32 h-32 text-gray-400" />
                      )}
                    </label>
                    {/* <input
                      id="user-image-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                    onChange={handleImageUpload}
                    disabled={!isEditing || (user.role !== 'admin' && user.email !== userDetails.email)}
                    /> */}
                    {/* <p className="text-sm text-gray-500 mt-2">Click to upload an image</p>
                    <p className="text-xs text-gray-400 mt-1">Max size: 2MB</p> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </Layout>
  );
};

export default UserDetails;